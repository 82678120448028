"use client";
import {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import dynamic from "next/dynamic";
import { Box, TextField, Stack, CardActionArea, Avatar } from "@mui/material";
import {
  AudiotrackRounded as AudioIcon,
  ArrowForwardRounded as SendIcon
} from "@mui/icons-material";
import ChatIcon from "@mui-symbols-material/w400/ChatPasteGo2FilledRounded";

import { useStore, observer } from "../../../../../service/mobx";
import ButtonText from "../../../../../component/Button/Text";
import ButtonOutlined from "../../../../../component/Button/Outlined";
import Skeleton from "../../../../../component/Skeleton";
import ButtonFilled from "../../../../../component/Button/Filled";

const Markdown = dynamic(() =>
  import("../../../../../component/Markdown/Message")
);
const ImageIcon = dynamic(() =>
  import("@mui-symbols-material/w400/ImageRounded")
);
const CodeBox = dynamic(() =>
  import("../../Api").then(module => module.CodeBox)
);
const ReactJson = dynamic(() => import("react-json-view"));

export default function useIO(task) {
  const [io, setIo] = useState();
  const { model } = useStore();

  task ??= model.node.task;

  useEffect(() => {
    const map = {
      "automatic-speech-recognition": {
        label: "Speech",
        labelOutput: "Text",
        Input: InputAudio,
        Output: OutputText,
        Examples: ExamplesAudio
      },
      "audio-classification": {
        label: "Audio",
        labelOutput: "Class",
        Input: InputAudio,
        Output: OutputJSON,
        Examples: ExamplesAudio
      },
      chat: {
        label: "Chat",
        Input: InputChat
      },
      "depth-estimation": {
        label: "Estimate image depth",
        labelOutput: "Depth",
        Input: InputImage,
        Output: OutputDepthEstimation,
        Examples: ExamplesInputImage
      },
      "document-question-answering": {
        label: "Document Q&A",
        labelOutput: "Result",
        Input: InputVisualQA,
        Output: OutputJSON,
        Examples: ExamplesInputQADocs
      },
      "feature-extraction": {
        label: "Text",
        labelOutput: "Vector",
        Input: InputText,
        Output: OutputJSON
      },
      "fill-mask": {
        label: "Fill in the blank",
        labelOutput: "Result",
        Input: InputText,
        Output: OutputJSON
      },
      "image-classification": {
        label: "Image",
        labelOutput: "Class",
        Input: InputImage,
        Output: OutputJSON,
        Examples: ExamplesInputImage
      },
      "image-feature-extraction": {
        label: "Image",
        labelOutput: "Vector",
        Input: InputImage,
        Output: OutputJSON,
        Examples: ExamplesInputImage
      },
      "image-segmentation": {
        label: "Image",
        labelOutput: "Result",
        Input: InputImage,
        Output: OutputJSON,
        Examples: ExamplesInputImage
      },
      "image-to-text": {
        label: "Image",
        labelOutput: "Text",
        Input: InputImage,
        Output: OutputJSON,
        Examples: ExamplesInputImage
      },
      "mask-generation": {
        label: "Image",
        labelOutput: "Result",
        Input: InputImage,
        Output: OutputJSON,
        Examples: ExamplesInputImage,
        disabled: true
      },
      "object-detection": {
        label: "Image",
        labelOutput: "Result",
        Input: InputImage,
        Output: OutputJSON,
        Examples: ExamplesInputImage
      },
      "question-answering": {
        label: "Q&A",
        labelOutput: "Result",
        Input: InputQuestionAnswering,
        Output: OutputJSON
      },
      "sentence-similarity": {
        label: "Text",
        labelOutput: "Result",
        Input: InputText,
        Output: OutputJSON
      },
      summarization: {
        labelOutput: "Summary",
        label: "Text",
        Input: InputText,
        Output: OutputText
      },
      "text-classification": {
        label: "Text",
        labelOutput: "Class",
        Input: InputText,
        Output: OutputJSON
      },
      "text-generation": {
        label: "Input",
        labelOutput: "Generated text",
        Input: InputText,
        Output: OutputTextCompletion
      },
      "text-to-audio": {
        label: "Text",
        labelOutput: "Audio",
        Input: InputText,
        Output: OutputAudio,
        disabled: true
      },
      "text-to-image": {
        label: "Text",
        labelOutput: "Image",
        Input: InputText,
        Output: OutputImg
      },
      "text-to-speech": {
        label: "Text",
        labelOutput: "Speech",
        Input: InputText,
        Output: OutputAudio,
        disabled: true
      },
      "text-to-video": {
        label: "Text",
        labelOutput: "Video",
        Input: InputText,
        Output: OutputVideo
      },
      "token-classification": {
        label: "Text",
        labelOutput: "Result",
        Input: InputText,
        Output: OutputJSON
      },
      translation: {
        label: "Text",
        labelOutput: "Translation",
        Input: InputText,
        Output: OutputText
      },
      "unconditional-image-generation": {
        label: "Generate images without input",
        labelOutput: "Generated image",
        Input: () => null,
        Output: OutputImg
      },
      "video-classification": {
        label: "Video",
        labelOutput: "Class",
        disabled: true,
        Input: InputVideo,
        Output: OutputJSON,
        Examples: ExamplesVideos
      },
      "visual-question-answering": {
        label: "Visual Q&A",
        labelOutput: "Result",
        Input: InputVisualQA,
        Output: OutputJSON,
        Examples: ExamplesInputImage
      },
      "zero-shot-classification": {
        label: "Classify text",
        labelOutput: "Result",
        Input: InputZeroShotClassification,
        Output: OutputJSON
      },
      "zero-shot-image-classification": {
        label: "Classify images",
        labelOutput: "Result",
        Input: InputZeroShotImageClassification,
        Output: OutputJSON,
        Examples: ExamplesInputImage
      },
      "zero-shot-object-detection": {
        label: "Detect objects",
        labelOutput: "Result",
        Input: InputZeroShotImageClassification,
        Output: OutputJSON,
        Examples: ExamplesInputImage
      }
    };

    map["text2text-generation"] = map["text-generation"];

    setIo(map[task]);
  }, [task]);

  return io || { notLoaded: true };
}
//
// inputs
//
const InputText = observer(function InputText() {
  const { model } = useStore();

  return (
    <InputTextField
      multiline
      minRows={4}
      value={model.input || ""}
      disabled={model.running}
      maxRows={16}
      onChange={event => {
        model.set.input(event.target.value);
        model.set.output();
      }}
    />
  );
});

function useOnChange(model, setInvalid) {
  const onChange = useCallback(
    ({ target: { files, value } }) => {
      try {
        var invalid = false;

        if (files) {
          const reader = new FileReader();

          reader.readAsDataURL(files[0]);
          reader.onload = event =>
            model.set.input(
              typeof model.input === "string"
                ? event.target.result
                : {
                    ...model.input,
                    url: undefined,
                    base64: event.target.result
                  }
            );
        } else {
          model.set.input(
            typeof model.input === "string"
              ? value
              : { ...model.input, base64: undefined, url: value }
          );
          new URL(value);
        }
      } catch {
        invalid = true;
      } finally {
        setInvalid(invalid);
      }
    },
    [model, setInvalid]
  );

  return onChange;
}

function InputAudio({ setInvalid, invalidInput }) {
  const ref = useRef();
  const { model } = useStore();
  const onChange = useOnChange(model, setInvalid);

  return (
    <>
      <InputTextField
        value={
          model.input?.url ??
          (model.input?.base64 ? "(local file selected)" : model.input)
        }
        onChange={onChange}
        disabled={model.running}
        label="audio"
        placeholder="url or base64 data url"
        helperText={
          invalidInput
            ? "Invalid ULR. It should look something like https://url.com/audio.wav"
            : undefined
        }
      />
      <ButtonText
        fullWidth
        disabled={model.running}
        label="Upload audio file"
        onClick={() => ref.current.click()}
      />
      <input
        ref={ref}
        type="file"
        onChange={onChange}
        accept=".wav, audio/wav"
        style={{ display: "none" }}
      />
    </>
  );
}

function InputVideo({ invalidInput, setInvalid }) {
  const ref = useRef();
  const { model } = useStore();
  const onChange = useOnChange(model, setInvalid);

  return (
    <>
      <InputTextField
        value={
          model.input?.url ??
          (model.input?.base64 ? "(local file selected)" : model.input)
        }
        label="video"
        onChange={onChange}
        disabled={model.running}
        placeholder="url or base64 data url"
        helperText={
          invalidInput
            ? "Invalid ULR. It should look something like https://url.com/video.mp4"
            : undefined
        }
      />
      <ButtonText
        fullWidth
        disabled={model.running}
        label="Upload video"
        onClick={() => ref.current.click()}
      />
      <input
        ref={ref}
        type="file"
        onChange={onChange}
        style={{ display: "none" }}
        accept=".mp4, video/mp4,.webm, video/webm,.ogg, video/ogg,.ogv, video/ogv,.mov, video/quicktime,.avi, video/x-msvideo, video/avi,.mpg, video/mpeg,.mpeg, video/mpg,.3gp, video/3gpp,.3g2, video/3gpp2"
      />
    </>
  );
}
function InputImage({ invalidInput, setInvalid, label, value }) {
  const ref = useRef();
  const { model } = useStore();
  const onChange = useOnChange(model, setInvalid);

  return (
    <>
      <InputTextField
        value={
          value ??
          model.input?.url ??
          (model.input?.base64 ? "(local file selected)" : model.input)
        }
        label={label || "image"}
        onChange={onChange}
        disabled={model.running}
        placeholder="url or base64 data url"
        helperText={
          invalidInput
            ? "Invalid ULR. It should look something like https://url.com/image.png"
            : undefined
        }
      />
      <ButtonText
        fullWidth
        label="Upload image"
        disabled={model.running}
        onClick={() => ref.current.click()}
      />
      <input
        ref={ref}
        type="file"
        onChange={onChange}
        accept=".png, image/png"
        style={{ display: "none" }}
      />
    </>
  );
}

const InputQuestionAnswering = observer(function InputQuestionAnswering({
  invalidInput,
  setInvalid
}) {
  const { model } = useStore();

  return (
    <>
      <InputTextField
        label="Context"
        disabled={model.running}
        value={model.input?.question}
        onChange={event =>
          model.set.input({
            ...model.input,
            context: event.target?.value ?? ""
          })
        }
      />
      <InputImage
        label="Image context"
        setInvalid={setInvalid}
        invalidInput={invalidInput}
        value={
          model.input?.url ??
          (model.input?.base64 ? "(local file selected)" : undefined)
        }
      />
    </>
  );
});
const InputVisualQA = observer(function InputVisualQA({
  invalidInput,
  setInvalid
}) {
  const { model } = useStore();

  return (
    <>
      <InputTextField
        label="Question"
        disabled={model.running}
        value={model.input?.question}
        onChange={event =>
          model.set.input({
            ...model.input,
            question: event.target?.value ?? ""
          })
        }
      />
      <InputImage
        label="Image context"
        setInvalid={setInvalid}
        invalidInput={invalidInput}
        value={
          model.input?.url ??
          (model.input?.base64 ? "(local file selected)" : undefined)
        }
      />
    </>
  );
});
const InputZeroShotClassification = observer(
  function InputZeroShotClassification() {
    const { model } = useStore();

    return (
      <>
        <InputTextField
          label="text"
          disabled={model.running}
          value={model.input?.text}
          onChange={event =>
            model.set.input({
              ...model.input,
              text: event.target?.value ?? ""
            })
          }
        />
        <InputTextField
          label="classes"
          disabled={model.running}
          value={model.input?.candidate_labels?.toString()}
          onChange={event =>
            model.set.input({
              ...model.input,
              candidate_labels: event.target?.value?.split(",") ?? ""
            })
          }
        />
      </>
    );
  }
);
const InputZeroShotImageClassification = observer(
  function InputZeroShotImageClassification() {
    const { model } = useStore();

    return (
      <>
        <InputTextField
          label="classes"
          disabled={model.running}
          value={model.input?.candidate_labels?.toString()}
          onChange={event =>
            model.set.input({
              ...model.input,
              candidate_labels: event.target?.value?.split(",") ?? ""
            })
          }
        />
        <InputTextField
          label="image"
          disabled={model.running}
          value={model.input?.url}
          onChange={event =>
            model.set.input({
              ...model.input,
              url: event.target?.value ?? ""
            })
          }
        />
      </>
    );
  }
);
const InputChat = observer(function InputChat({
  runModel,
  input,
  output,
  modelId,
  modelPage = false
}) {
  const [loading, setLoading] = useState(true);
  const [newMessage, setNewMessage] = useState({ role: "user", content: "" });
  const { model } = useStore();
  const noInput = modelPage ? input === undefined : model.input === undefined;

  output ??= model.output;
  modelId ??= model.node.modelId;

  useEffect(() => {
    if (noInput && modelPage === false) {
      fetch(`/api/model/widget/${model.node.modelId}`).then(async res => {
        if (res.status === 200) {
          const { output } = await res.json();

          model.set.input(output);
          setNewMessage({ role: "user", content: "" });
        } else {
          setNewMessage({ role: "user", content: "Hello" });
        }

        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [model, noInput, modelPage]);

  useEffect(() => {
    if (modelPage === false) {
      model.set.input(model.output);
    }
  }, [model, model.output, modelPage]);

  console.log({ output });

  return loading ? null : (
    <Stack
      spacing={2}
      p={{ compact: 0, expanded: 2 }}
      sx={{
        borderColor: "var(--outline-variant)",
        borderRadius: "var(--shape-md-round)"
      }}
    >
      {output?.map?.(({ role, content }, index) => (
        <Box key={index} width="100%">
          <Box
            px={2}
            py={1}
            width="fit-content"
            borderRadius="var(--shape-xl-round)"
            justifySelf={role === "user" ? "flex-end" : undefined}
            typography={role === "user" ? "bodyLg" : "paperBody1"}
            color={
              role === "user"
                ? "var(--primary-on-color)"
                : "var(--secondary-on-container)"
            }
            bgcolor={
              role === "user"
                ? "var(--primary-color)"
                : "var(--secondary-container)"
            }
          >
            <Markdown md={content} />
          </Box>
        </Box>
      )) ?? (
        <Box width="100%">
          <Stack
            useFlexGap
            alignItems="center"
            spacing={2}
            direction="row"
            p={3}
            mb={4}
            width="fit-content"
            borderRadius="var(--shape-sm)"
            justifySelf="center"
            typography="bodyLg"
            color="var(--colors-info-40)"
            bgcolor="var(--colors-info-90)"
          >
            <ChatIcon />
            <div>
              Send a message to start chatting with{" "}
              <Box component="strong">{modelId}</Box>
            </div>
          </Stack>
        </Box>
      )}
      <Stack
        direction="row"
        alignItems="center"
        py={1}
        border={1}
        spacing={2}
        px={{ compact: 2, expanded: 3 }}
        bgcolor="var(--surface-container-lowest)"
        borderRadius="var(--shape-round)"
        borderColor="var(--outline-variant)"
        boxShadow="var(--elevation-2)"
        sx={{
          transition: "all 300ms var(--motion-easing-standard)",
          "&:hover": {
            bgcolor: "var(--surface-container-low)"
          }
        }}
      >
        <TextField
          autoFocus
          fullWidth
          value={newMessage.content}
          variant="standard"
          placeholder="Your chat message here..."
          sx={{ "& fieldset": { display: "none" } }}
          slotProps={{ input: { disableUnderline: true } }}
          onChange={event =>
            setNewMessage({ role: "user", content: event.target.value })
          }
        />
        <ButtonFilled
          label="Send"
          disabled={model.running || newMessage.content === ""}
          IconEnd={SendIcon}
          onClick={() => {
            model.set.input(
              model.input ? [...model.input, newMessage] : [newMessage]
            );

            runModel();
            setNewMessage({ role: "user", content: "" });
          }}
        />
      </Stack>
    </Stack>
  );
});
export const InputTextField = ({ value = "", sx, ...props }) => (
  <TextField
    value={value}
    variant="filled"
    slotProps={{
      input: {
        sx: {
          borderRadius: "var(--shape-lg-top)",
          bgcolor: "var(--surface-container-highest)",
          "&::before": {
            borderColor: "var(--outline-variant)"
          },
          "&:hover": {
            "&::before": {
              borderBottomColor: "var(--outline-color) !important"
            }
          }
        }
      },
      htmlInput: {
        sx: {
          color: "var(--surface-on-color)",
          typography: { compact: "bodySm", expanded: "bodyLg" }
        }
      }
    }}
    sx={sx}
    {...props}
  />
);

//
// outputs
//
const OutputText = ({ task, output }) => (
  <OutputTextTemplate task={task} output={output} />
);
const OutputTextCompletion = ({ task, input, output = "" }) => (
  <OutputTextTemplate task={task} output={input + " " + output} />
);
function OutputJSON({ output }) {
  const [json, setJSON] = useState();
  const [sizeInMB, setSize] = useState(0);

  useEffect(() => {
    if (output) {
      const json = JSON.stringify(output, undefined, 2);

      setJSON(json);
      setSize((json.length * 2) / (1024 * 1024));
    }
  }, [output]);

  return sizeInMB < 0.5 ? (
    <CodeBox language="json" code={json} />
  ) : (
    <Box
      p={2}
      maxHeight={480}
      overflow="auto"
      bgcolor="var(--dark-surface-dim)"
      borderRadius="var(--shape-md-round)"
      color="var(--dark-surface-on-color)"
      sx={{
        "& > div": {
          lineHeight: "20px",
          fontSize: 14,
          fontWeight: 400,
          typography: "code",
          bgcolor: "var(--dark-surface-dim) !important"
        }
      }}
    >
      <ReactJson
        sortKeys
        src={output}
        name={false}
        collapsed={1}
        theme="monokai"
        enableClipboard={false}
      />
    </Box>
  );
}

const OutputImg = ({ output }) => (
  <OutputWrapper>
    <Avatar
      src={output}
      sx={{
        height: "100%",
        width: "100%",
        bgcolor: "transparent",
        borderRadius: "var(--shape-sm)"
      }}
      slotProps={{
        img: {
          sx: { maxWidth: "100%" },
          onLoad({ target }) {
            target.style.aspectRatio = `${target.naturalWidth} / ${target.naturalHeight}`;
          }
        }
      }}
    >
      <ImageIcon
        sx={{ color: "var(--secondary-color)", width: "100%", height: "100%" }}
      />
    </Avatar>
  </OutputWrapper>
);
const OutputAudio = ({ output }) => (
  <OutputWrapper>
    <audio key={output} controls>
      <source type="audio/wav" src={output} />
    </audio>
  </OutputWrapper>
);
const OutputVideo = ({ output }) => (
  <OutputWrapper>
    <Box
      key={output}
      controls
      component="video"
      sx={{ borderRadius: "var(--shape-sm)" }}
    >
      <source type="video/mp4" src={output} />
    </Box>
  </OutputWrapper>
);

const OutputTextTemplate = ({ output }) => (
  <Box
    p={2}
    overflow="auto"
    maxHeight="100vh"
    color="var(--dark-surface-on-color)"
    borderRadius="var(--shape-md-round)"
    bgcolor="var(--dark-surface-container-high)"
  >
    <Suspense fallback={<Skeleton height="50%" />}>
      <Markdown md={output} />
    </Suspense>
  </Box>
);
const OutputWrapper = ({ children }) => (
  <Stack
    p={1}
    alignItems="center"
    justifyContent="center"
    sx={{ borderRadius: "var(--shape-sm)" }}
  >
    {children}
  </Stack>
);
function OutputDepthEstimation({ output }) {
  const [url, setUrl] = useState();

  useEffect(() => {
    if (output?.depth_png) {
      const base64Decoded = atob(output.depth_png);
      let i = base64Decoded.length;
      const u8arr = new Uint8Array(i);

      while (i--) {
        u8arr[i] = base64Decoded.charCodeAt(i);
      }

      const url = URL.createObjectURL(new Blob([u8arr], { type: "image/png" }));

      setUrl(url);

      return () => URL.revokeObjectURL(url);
    }
  }, [output]);

  return (
    <>
      <OutputImg output={url} />
      <OutputJSON output={output} />
    </>
  );
}
//
// examples
//
const ExamplesInputImage = observer(function ExamplesInputImage({
  images,
  setInvalid
}) {
  const [examples] = useState(
    images || [
      "https://ocean.si.edu/sites/default/files/styles/3_2_largest/public/2023-11/Screen_Shot_2018-04-16_at_1_42_56_PM.png.webp",
      "https://www.mccanndogs.com/cdn/shop/articles/living-with-cats-dogs-689902.jpg",
      "https://media.cnn.com/api/v1/images/stellar/prod/181002113456-01-golden-gate-bridge-restricted.jpg?q=w_1110,c_fill/f_webp"
    ]
  );
  const { model } = useStore();

  return (
    <Stack direction="row" spacing={1}>
      {examples.map((url, index) => (
        <CardActionArea
          key={index}
          disabled={model.running}
          onClick={() => {
            setInvalid(false);
            model.set.input(
              typeof model.input === "string"
                ? url
                : { ...model.input, base64: undefined, url }
            );
          }}
        >
          <Box
            src={url}
            component="img"
            alt={`Example image ${index + 1}`}
            sx={{
              width: "100%",
              height: "100%",
              cursor: "pointer",
              objectFit: "cover",
              borderRadius: "var(--shape-sm)",
              filter: model.running ? "grayscale(1)" : undefined
            }}
          />
        </CardActionArea>
      ))}
    </Stack>
  );
});
const ExamplesInputQADocs = ({ setInvalid }) => (
  <ExamplesInputImage
    setInvalid={setInvalid}
    images={[
      "https://templates.invoicehome.com/invoice-template-us-neat-750px.png",
      "https://t3.ftcdn.net/jpg/01/82/01/18/360_F_182011806_mxcDzt9ckBYbGpxAne8o73DbyDHpXOe9.jpg",
      "https://d3i71xaburhd42.cloudfront.net/f85f4431493ad1b3aa669c5371ca442f60f13c59/124-TableA.4-1.png"
    ]}
  />
);
const ExamplesAudio = observer(function ExamplesAudio() {
  const [examples] = useState([
    [
      "down",
      "https://huggingface.co/datasets/huggingfacejs/tasks/resolve/main/audio-classification/audio.wav"
    ],
    [
      "book",
      "https://huggingface.co/datasets/huggingfacejs/tasks/resolve/main/automatic-speech-recognition/input.flac"
    ]
  ]);
  const { device, model } = useStore();

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {examples.map(([label, audio], index) => (
        <Fragment key={index}>
          <ButtonOutlined
            label={label}
            size={device.isPhone ? "small" : undefined}
            disabled={model.running}
            IconStart={AudioIcon}
            onClick={() => {
              const track = document.getElementById(label);
              const isPaused = track.currentTime === 0;

              for (const [label] of examples) {
                const audio = document.getElementById(label);

                audio.pause();
                audio.currentTime = 0;
              }

              if (isPaused) {
                track.play();
              } else {
                track.pause();
              }

              model.set.input(audio);
            }}
          />
          <audio id={label} src={audio} style={{ display: "none" }} />
        </Fragment>
      ))}
    </Stack>
  );
});

const ExamplesVideos = observer(function ExamplesVideos({ setInvalid }) {
  const [hover, setHover] = useState();
  const [examples] = useState([
    "https://cdn.bytez.com/model/example/wizard-cat.mp4",
    "https://cdn.bytez.com/model/example/meditate.mp4",
    "https://cdn.bytez.com/model/example/lion.mp4"
  ]);
  const { model } = useStore();

  return (
    <Stack direction="row" spacing={1} height={240}>
      {examples.map((url, index) => (
        <CardActionArea
          key={index}
          disabled={model.running}
          sx={{
            flex: index === hover ? 6.18 : 1.91,
            transition: "all 1s var(--motion-easing-emphasized)"
          }}
          onClick={() => {
            setInvalid(false);
            model.set.input({ ...model.input, base64: undefined, url });
          }}
        >
          <Box
            muted
            component="video"
            sx={{
              width: "100%",
              height: "100%",
              cursor: "pointer",
              objectFit: "cover",
              borderRadius: "var(--shape-sm)",
              filter: model.running ? "grayscale(1)" : undefined
            }}
            onMouseEnter={event => {
              // Play on mouse enter
              event.target.play();

              setHover(index);
            }}
            onMouseLeave={event => {
              // / Pause and reset on mouse leave
              event.target.pause();
              event.target.currentTime = 0; // Reset to the beginning

              setHover();
            }}
          >
            <source type="video/mp4" src={url} />
          </Box>
        </CardActionArea>
      ))}
    </Stack>
  );
});
